<template>
  <div>
    <v-list-item>
      <v-select
        outlined
        dense
        label="Select Printer Type"
        :items="printerTypes"
        class="pa-0"
        v-model="printer_type"
      />
    </v-list-item>
    <v-list-item>
      <v-select
        multiple
        outlined
        dense
        label="Send SMS On"
        :items="billing.sms_options"
        class="pa-0"
        v-model="billing.sms_selected_options"
      />
    </v-list-item>
    <v-list-item>
      <v-select
        multiple
        outlined
        dense
        label="Display Authorized Signature Automatically On"
        :items="billing.signature_options"
        class="pa-0"
        v-model="billing.signature_selected_options"
      />
    </v-list-item>
    <v-list-item>
      <v-select
        multiple
        outlined
        dense
        label="Display Student Image  On"
        :items="billing.image_options"
        class="pa-0"
        v-model="billing.image_selected_options"
      />
    </v-list-item>
    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="error"
        v-model="exclude_transportation"
      >
        <template v-slot:label>
          <div style="color: #333">
            Exclude Transportation Charge
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                Student's Transportation fee won't show up in the invoice
                generation.
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>

    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="error"
        v-model="billing.include_pending_dues"
      >
        <template v-slot:label>
          <div style="color: #333">
            Include Pending Dues
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal"
                >Check this if you want to include pending dues in receipt print
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>

    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="error"
        v-model="billing.enable_penalty"
      >
        <template v-slot:label>
          <div style="color: #333">
            Enable Penalty
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal"
                >Check this if you want include penalty on dues according to
                your penalty settings.
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>

    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="error"
        v-model="billing.enable_customized"
      >
        <template v-slot:label>
          <div style="color: #333">
            Enable Customized Fee
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                Enable this option to add customized fee heads (Setting > Fee
                Heads > Customize) on your generated bill.
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>
    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="error"
        v-model="billing.enable_individual_customized_fee"
      >
        <template v-slot:label>
          <div style="color: #333">
            Enable Individual Customized Fee
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                Enable this option to add Individual ECA fee heads (Setting > Individual Heads) on your generated bill.
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>

    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="error"
        v-model="billing.multiple_month"
      >
        <template v-slot:label>
          <div style="color: #333">
            Mutiple Month Bill Generate
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                Enable this option to allow multiple month on bill generate at
                once.
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>
    <v-list-item v-if="false">
      <v-checkbox
        style="margin-right: 20px"
        color="error"
        v-model="billing.disable_eca_charge"
      >
        <template v-slot:label>
          <div style="color: #333">
            Disable ECA charge

            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                When checked the ECA Heads Amount will not be generated
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>

    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="error"
        v-model="billing.sms_fee_receipt"
      >
        <template v-slot:label>
          <div style="color: #333">
            Send Sms on Fee Receipt

            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                When checked Sms will be sent on fee receipt
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>

    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="error"
        v-model="billing.sms_fee_cancelled"
      >
        <template v-slot:label>
          <div style="color: #333">
            Send Sms on Fee Cancelled

            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                When checked Sms will be sent on fee Cancelled
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>



    <v-list-item>
      <!-- <v-checkbox
        style="margin-right: 20px"
        color="error"
      >
        <template v-slot:label>
          <div style="color: #333">
            Fee payment success message
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                Send a sms message whenever a fee payment was successfully done. 
              </small><br><br>
            </p>
          </div>
        </template>
      </v-checkbox> -->
      
      <div>
        <small style="color: #777; font-size: 12px; font-weight: normal; margin-top: 5px;">
          <a href="javascript:void(0);"  @click="openDialog=true, compKey=compKey+1" >Edit template </a> and 
          <a href="javascript:void(0);" @click="getBalance(),openSmsStatus=true">check balance.</a> 
        </small>
      </div>
    </v-list-item>

    <!-- <v-list-item>
      <br>
      <v-btn outlined color="success" @click="openDialog=true, compKey=compKey+1"  v-on:resetAll="resetAll()">
        Edit Template
      </v-btn>
    </v-list-item> -->



    <v-list-item></v-list-item>
    <v-list-item>
      <v-btn
        :disabled="billing.saving"
        :loading="billing.saving"
        large
        block
        color="info outline"
        @click="billingSubmit"
        >Save</v-btn
      >
    </v-list-item>
    <v-dialog v-model="openDialog">
      <templateSetup :reuseComponentFlag="true" v-on:reset="resetAll()" :key="compKey" v-on:billingMsg="billingMsg" settingFor="billing" :billingMessage="billing_message"></templateSetup>
    </v-dialog>
    <v-dialog v-model="openSmsStatus" width="650px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">SMS Status</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <v-sms-stats
          :loading="fetchingStats"
          :smsQueue="sms_queue"
          :balance="balance"
          :ratePerSMS="rate_per_sms"
          
        ></v-sms-stats>
        <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                text
                @click="openSmsStatus=false"
                >Close</v-btn
              >

            </v-card-actions>
        </v-card-text>
      </v-card>
     
    </v-dialog>
  </div>
</template>
<script>
import templateSetup from "../../../../sms/_components/Templates/index.vue"
export default {
	components:{templateSetup},
	data: () => ({
		compKey:0,
		openDialog:false,
		openSmsStatus:false,
		balance:0,
		sms_queue:0,
		rate_per_sms:0,
		sms_stats: {},
		fetchingStats:false,
		billing: {
			sms_options: [
				{ value: 'fee-receipt', text: 'Fee Receipt' },
				{ value: 'fee-general-receipt', text: 'Fee General Receipt' },
				{ value: 'bill-due', text: 'Due Report' },
			],
			signature_options: [
				{ value: 'fee-receipt', text: 'Fee Receipt' },
				{ value: 'invoice', text: 'Invoice' },
			],
			image_options: [
				{ value: 'fee-receipt', text: 'Fee Receipt' },
				{ value: 'invoice', text: 'Invoice' },
			],
			image_selected_options: [],
			signature_selected_options: [],
			sms_selected_options: [],
			include_pending_dues: false,
			enable_penalty: false,
			saving: false,
			enable_customized: false,
			multiple_month: false,
			disable_eca_charge: false,
      sms_fee_receipt: false,
      sms_fee_cancelled: false,
      enable_individual_customized_fee :false,
      sms_message:''
		},
		printer_type: 'ledger',
		printerTypes: [
			{ value: 'laser', text: 'Laser' },
			{ value: 'dot-matrix', text: 'Dot Matrix' },
		],
		exclude_transportation: false,
		result_block: false,
		billing_message:'',
		enable_individual_customized_fee :false
	}),
	mounted() {
		this.getBillingSetting();
	},
	methods: {
    
		getBalance() {
			this.fetchingStats = true;
			this.$rest
				.get("/api/sms/dashboard")
				.then((res) => {
					this.balance = res.data.balance;
					this.sms_queue = res.data.smsqueue;
					this.rate_per_sms = res.data.rate_per_sms;
					this.sms_stats = res.data;
				})
				.finally(() => {
					this.fetchingStats = false;
				});
		},
		getBillingSetting() {
			this.$rest.get('/api/billing-setting').then(({ data }) => {
				this.printer_type = data.billing ? data.billing.printer_type : '';
				this.billing.sms_selected_options = data.billing.sms_on;
				this.billing.signature_selected_options = data.billing.signature_on;
				this.billing.image_selected_options = data.billing.student_image_on;
				this.exclude_transportation =
          data.billing.exclude_transportation === 'true';
				this.billing.include_pending_dues =
          data.billing.include_pending_dues === 'true';
				this.billing.enable_penalty = data.billing.enable_penalty === 'true';

				this.billing.enable_customized =
          data.billing.enable_customized === 'true';
				this.billing.enable_individual_customized_fee =
          data.billing.enable_individual_customized_fee === 'true';
				this.billing.multiple_month = data.billing.multiple_month === 'true';
				this.billing.disable_eca_charge = data.billing.disable_eca_charge === 'true';
				this.billing.sms_fee_receipt = data.billing.sms_fee_receipt === 'true';
				this.billing.sms_fee_cancelled = data.billing.sms_fee_cancelled === 'true';
				this.billing_message=data.billing.billing_sms_message;
				// this.billing.fee_payment_sms = data.billing.fee_payment_sms === 'true';
			});
		},
		billingSubmit() {
			this.billing.saving = true;
			this.$rest
				.post('/api/setting', {
					printer_type: this.printer_type,
					exclude_transportation: this.exclude_transportation.toString(),
					sms_on: this.billing.sms_selected_options
						? this.billing.sms_selected_options.join(',')
						: '',
					signature_on: this.billing.signature_selected_options
						? this.billing.signature_selected_options.join(',')
						: '',
					student_image_on: this.billing.image_selected_options
						? this.billing.image_selected_options.join(',')
						: '',
					include_pending_dues: this.billing.include_pending_dues.toString(),
					enable_penalty: this.billing.enable_penalty.toString(),
					enable_customized: this.billing.enable_customized.toString(),
					enable_individual_customized_fee: this.billing.enable_individual_customized_fee.toString(),
					multiple_month: this.billing.multiple_month.toString(),
					disable_eca_charge: this.billing.disable_eca_charge.toString(),
					sms_fee_receipt: this.billing.sms_fee_receipt.toString(),
					sms_fee_cancelled: this.billing.sms_fee_cancelled.toString(),
					// fee_payment_sms: this.billing.fee_payment_sms.toString(),
					flag: 'billing',
					billing_sms_message : this.billing.sms_message,
				})
				.then(({ data }) => {
					this.$events.fire('notification', { message: data.message });
					this.getBillingSetting();
					this.resetAll();
				})
				.finally(() => {
					this.billing.saving = false;
				});
		},
		resetAll(){
			this.openDialog = false;
		},
		billingMsg(msg){
			this.billing.sms_message = msg;
			this.billingSubmit();

		}
	},
};
</script>

<style>
.vdialognew.v-dialog.v-dialog--active.v-dialog--persistent{
  max-width: 900px !important;
}
</style>
<style>
.v-dialog--custom {
  width: 900px !important;
}
</style>
